<template>
  <div class="card p-1">
    <!-- {{ this.detall }} -->
    <!-- <div>
      <p>{{ this.$route.params }}</p>
    </div> -->
    <div class="custom-search">
      <div class="title head">
        <p class="h2" style="color: #558cef">ทะเบียนคุมทรัพย์สิน</p>
      </div>
      <hr />
      <!-- advance search input -->
      <div class="custom-search row">
        <!-- <b-form-group class="col-12 col-md-3">
          <label>รหัสทรัพย์สิน:</label>
          <b-input v-model="equipment" placeholder="รหัสทรัพย์สิน" />
        </b-form-group> -->
        <!-- <b-form-group class="col-12 col-md-3">
          <label>อายุการใช้งาน:</label>
          <b-input v-model="lifetime" placeholder="อายุการใช้งาน" />
        </b-form-group> -->
      </div>
      <div class="row justify-content-between">
        <div class="row col-6 mb-1">
          <div class="ml-1">
            <b-button variant="danger" :to="{ name: 'controlregistrationall' }"> กลับ </b-button>
          </div>
          <!-- <div class="ml-1">
            <b-button variant="success" @click="get_table_report"> ค้นหา </b-button>
          </div>
          <div class="ml-1">
            <b-button variant="danger" @click="cc_report"> ยกเลิก </b-button>
          </div> -->
          <div class="ml-1">
            <b-button variant="outline-primary" @click="exportExcel"> Export Excel </b-button>
          </div>
        </div>
        <div class="mr-2">
          <b-button variant="outline-primary" @click="exportpdf"> พิมพ์รายงานแยกตามกลุ่มทรัพย์สิน PDF </b-button>
        </div>
      </div>
    </div>
    <div class="row justify-content-start ml-1">
      <div class="text-danger">ประเภท</div>
      <span class="ml-1 mr-1">{{ this.detall.e_name }}</span>
      <div class="text-danger">หมายเลขทรัพย์สิน</div>
      <span class="ml-1 mr-1">{{ this.detall.e_number }}</span>
      <div class="text-danger">รายละเอียด</div>
      <span class="ml-1 mr-1">{{ this.detall.information }}</span>
    </div>
    <div class="row justify-content-start ml-1">
      <div class="text-danger">สถานที่ตั้ง/หน่วยงานที่รับผิดชอบ</div>
      <span class="ml-1 mr-1">{{ this.detall.work_gname2 ? this.detall.work_gname2 : this.detall.work_gname }}</span>
      <div class="text-danger">ผู้เบิก</div>
      <span class="ml-1 mr-1">{{
        `${this.detall.withdraw_person ? this.detall.withdraw_person : this.detall.response_person}`
      }}</span>
      <div class="text-danger">ผู้รับผิดฃอบ</div>
      <span class="ml-1 mr-1">{{ this.detall.responsename }}</span>
      <div class="text-danger">สถานที่จัดเก็บ</div>
      <span class="ml-1 mr-1">{{ this.detall.place }}</span>
    </div>
    <div class="row justify-content-start ml-1">
      <div class="text-danger">ชื่อผู้ขาย/ผู้รับจ้าง/ผู้บริจาค</div>
      <span class="ml-1 mr-1">{{ this.detall.vender }}</span>
      <div class="text-danger">อ้างอิงสัญญาฯ สัญญาเลขที่</div>
      <span class="ml-1 mr-1">{{ this.detall.important_number }}</span>
      <div class="text-danger">วันที่</div>
      <span class="ml-1 mr-1">{{ `${this.detall.important_date_th ? this.detall.important_date_th : ""}` }}</span>
      <!-- {{
        `${parseInt(this.detall.important_date.split('-')[2])} ${
          this.month[parseInt(this.detall.important_date.split('-')[1])]
        } ${Number(this.detall.important_date.split('-')[0]) + 543}`
      }} -->
      <div class="text-danger">เลขที่ใบส่งของ</div>
      <span class="ml-1 mr-1">{{ this.detall.invoice_number }}</span>

      <div class="text-danger">วันที่</div>
      <span class="ml-1 mr-1">{{ `${this.detall.invoice_dateth ? this.detall.invoice_date_th : ""}` }}</span>
    </div>
    <div class="row justify-content-start ml-1">
      <div class="text-danger">ที่อยู่</div>
      <span class="ml-1 mr-1">{{ this.detall.vender_address }}</span>
    </div>
    <div class="row justify-content-start ml-1">
      <div class="text-danger">ประเภทเงิน :</div>
      <span class="ml-1 mr-1">{{ this.detall.budget_type }}</span>
    </div>
    <div class="row justify-content-start ml-1">
      <div class="text-danger">วิธีการได้มา :</div>
      <span class="ml-1 mr-1">{{ this.detall.supply_by }}</span>
    </div>
    <!-- table -->
    <b-overlay :show="show" rounded="sm">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        theme="my-theme"
        :line-numbers="true"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'fullName'" class="text-nowrap">
            <b-avatar :src="props.row.avatar" class="mx-1" />
            <span>{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'listmenu'">
            <span>
              <b-button variant="outline-warning" :to="{ name: 'inseakuruplun', params: { id: props.row } }">
                <feather-icon icon="EyeIcon" class="mr-50" />
              </b-button>
            </span>
          </span>
          <span v-else-if="props.column.field === 'จำนวน'">
            <div class="text-right">{{}}</div>
          </span>
          <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
            <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
          </span>
          <span v-else-if="props.column.field === 'status'">
            <div v-if="props.row.status === 'รับเข้า'">
              <b-button variant="info">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'เบิก'">
              <b-button variant="warning">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'ยืม'">
              <b-button variant="danger">
                {{ props.row.status }}
              </b-button>
            </div>
            <div v-else-if="props.row.status === 'คืน'">
              <b-button variant="success">
                {{ props.row.status }}
              </b-button>
            </div>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '15', '25', '50', '100']"
                class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage1(value))"
              />
              <span class="text-nowrap">of {{ total }} entries</span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="handlePagechange1($event)"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import { BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import pdfMake from "pdfmake";
import pdfFonts from "../font/custom-fonts";
import _ from "lodash";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },
  data() {
    return {
      date: {
        startDate: "",
        endDate: "",
      },
      selected: null,
      options: [{ value: null, text: "เลือกปีงบประมาณ" }],
      equipment_code: "",
      e_number: "",
      serial_number: "",
      e_name: "",
      lifetime: "",
      currentPage: 1,
      pageLength: 100,
      total: "",
      show: false,
      dir: false,
      workgroup: null,
      workgroups: [],
      equipmentGroup: null,
      equipmentGroups: [],
      equipment: "",
      columns: [
        {
          label: "ปีที่",
          field: "rownum",
          width: "60px",
        },

        {
          label: "วัน-เดือน-ปี",
          field: "admitdate_th",
          width: "120px",
        },
        {
          label: "ที่เอกสาร",
          field: "equipment_code",
          width: "120px",
        },
        {
          label: "รายการ",
          field: "information",
          width: "250px",
        },
        {
          label: "จำนวนหน่วย",
          field: "unittype",
          width: "120px",
        },
        {
          label: "ราคาต่อหน่วย",
          field: "unitprice",
          width: "130px",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "มูลค่ารวม",
          field: "unitprice",
          width: "130px",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        // {
        //   label: 'ราคารวม',
        //   field: 'ราคารวม',
        //   width: '100px',
        // },
        {
          label: "อายุการใช้งาน",
          field: "expire",
          width: "130px",
          type: "number",
        },
        {
          label: "จำนวนวัน",
          field: "daycount",
          width: "130px",
          type: "number",
        },
        {
          label: "อัตราค่าเสื่อม (%)",
          field: "อัตราค่าเสื่อมราคาต่อปี",
          width: "150px",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "ค่าเสื่อมประจำปี",
          field: "ค่าเสื่อมราคาประจำปี",
          width: "150px",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "ค่าเสื่อมราคาสะสม",
          field: "ค่าเสื่อมราคาสะสม",
          width: "170px",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "มูลค่าสุทธิ",
          field: "มูลค่าสุทธิ",
          width: "120px",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
      ],
      detall: {},
      rows: [],
      searchTerm: "",
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  created() {
    _.isEmpty(this.$route.params.id) ? this.$router.push({ path: "/controlregistrationALL" }) : true;
    if (this.$route.params.id) {
      this.equipment = this.$route.params.id;
      this.get_table_report();
    }
    const y = new Date().getFullYear();
    const yy = y + 543;
    this.options.push({ value: yy, text: yy });
    for (let index = 1; index < 5; index++) {
      this.options.push({ value: yy - index, text: yy - index });
    }
    this.workgroups_table();
    this.equipment_table();
  },
  methods: {
    exportpdf() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Medium.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      const docDefinition = {
        pageMargins: [10, 80, 10, 50],
        pageSize: "A4",
        pageOrientation: "landscape",
        info: {
          title: "ทะเบียนคุมทรัพย์สิน",
        },
        header: [
          {
            text: "ทะเบียนคุมทรัพย์สิน",
            alignment: "center",
            margin: [0, 15, 0, 0],
          },
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            alignment: "center",
            margin: [0, 0, 0, 0],
          },
        ],

        content: [
          {
            text: `ประเภท: ${this.detall.e_name} หมายเลขทรัพย์สิน: ${this.detall.e_number} รายละเอียด: ${this.detall.information}`,
            style: "text",
          },
          {
            text: `สถานที่ตั้ง/หน่วยงานที่รับผิดชอบ: ${
              this.detall.work_gname2 ? this.detall.work_gname2 : this.detall.work_gname
            } ผู้เบิก: ${
              this.detall.withdraw_person ? this.detall.withdraw_person : this.detall.response_person
            } ผู้รับผิดฃอบ: ${this.detall.responsename} สถานที่จัดเก็บ: ${this.detall.place}`,
            style: "text",
          },
          {
            text: `ชื่อผู้ขาย/ผู้รับจ้าง/ผู้บริจาค: ${this.detall.vender} อ้างอิงสัญญาฯ สัญญาเลขที่: ${
              this.detall.important_number == null ? " " : this.detall.important_number
            } วันที่: ${this.detall.important_date_th} `,
            style: "text",
          },
          {
            text: `เลขที่ใบส่งของ: ${this.detall.invoice_number == null ? " " : this.detall.invoice_number} วันที่: ${
              this.detall.invoice_date_th
            } ที่อยู่: ${this.detall.vender_address} ประเภทเงิน: ${this.detall.budget_type} วิธีการได้มา: ${
              this.detall.supply_by
            }`,
            style: "text",
          },
          {
            table: {
              headerRows: 1,
              widths: [
                "auto",
                "auto",
                "auto",
                "*",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
              ],

              body: [
                [
                  {
                    text: `ปีที่`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `วัน-เดือน-ปี`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `ที่เอกสาร`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `รายการ`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `จำนวนหน่วย`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `ราคาต่อหน่วย`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `มูลค่ารวม`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `อายุการใช้งาน`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `จำนวนวัน`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `อัตราค่าเสื่อม (%)`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `ค่าเสื่อมประจำปี`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `ค่าเสื่อมราคาสะสม`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `มูลค่าสุทธิ`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                ],
                ...this?.rows.map((res, index) => {
                  return [
                    { text: res.rownum, style: "text_center" },
                    { text: res.admitdate_th, style: "text_left" },
                    { text: res.equipment_code, style: "text_left" },
                    { text: res.information, style: "text_left" },
                    { text: res.unittype, style: "text_left" },
                    {
                      text: `${
                        _.isEmpty(res.unitprice)
                          ? ""
                          : Number(res.unitprice)
                              .toString()
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      }`,
                      style: "text_number",
                    },
                    {
                      text: `${
                        _.isEmpty(res.unitprice)
                          ? ""
                          : Number(res.unitprice)
                              .toString()
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      }`,
                      style: "text_number",
                    },
                    { text: res.expire ? Number(res.expire) : "", style: "text_number" },
                    { text: res.daycount, style: "text_number" },
                    {
                      text: res.อัตราค่าเสื่อมราคาต่อปี,
                      style: "text_number",
                    },
                    {
                      text: `${
                        res.ค่าเสื่อมราคาประจำปี == 0
                          ? ""
                          : Number(res.ค่าเสื่อมราคาประจำปี)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      }`,
                      style: "text_number",
                    },
                    {
                      text: `${
                        res.ค่าเสื่อมราคาสะสม == 0
                          ? " "
                          : Number(res.ค่าเสื่อมราคาสะสม)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      }`,
                      style: "text_number",
                    },
                    {
                      text: `${
                        res.มูลค่าสุทธิ == 0
                          ? " "
                          : Number(res.มูลค่าสุทธิ)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      }`,
                      style: "text_number",
                    },
                  ];
                }),
              ],
            },
          },
        ],
        defaultStyle: {
          font: "Kanit",
        },
        styles: {
          tableExample: {
            margin: [0, 0, 0, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 8,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 9,
            bold: true,
            alignment: "center",
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 0, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            bold: false,
            fontSize: 7,
          },
          text_left: {
            alignment: "left",
            fontSize: 9,
          },
          text_number: {
            alignment: "right",
            bold: false,
            fontSize: 7,
          },
          text: {
            fontSize: 9,
          },
        },
      };
      pdfMake.createPdf(docDefinition).download(`ทะเบียนคุมทรัพย์สิน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}
`);
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: `ทะเบียนคุมทรัพย์สิน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}
`,
        columns: [
          {
            title: "ทะเบียนคุมทรัพย์สิน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: `ประเภท : ${this.detall.e_name} หมายเลขทรัพย์สิน: ${this.detall.e_number} รายละเอียด : ${this.detall.information}`,
                headerCellOptions: { textAlign: "" },
                children: [
                  {
                    title: `สถานที่ตั้ง/หน่วยงานที่รับผิดชอบ : ${this.detall.work_gname2 ? this.detall.work_gname2 : this.detall.work_gname} ผู้เบิก: ${
                      this.detall.withdraw_person ? this.detall.withdraw_person : this.detall.response_person
                    } ผู้รับผิดฃอบ : ${this.detall.responsename} สถานที่จัดเก็บ : ${this.detall.place}`,
                    headerCellOptions: { textAlign: "" },
                    children: [
                      {
                        title: `ชื่อผู้ขาย/ผู้รับจ้าง/ผู้บริจาค : ${this.detall.vender} อ้างอิงสัญญาฯ สัญญาเลขที่: ${
                          this.detall.important_number == null ? "" : this.detall.important_number
                        } วันที่ : ${this.detall.important_date_th} เลขที่ใบส่งของ : ${
                          this.detall.invoice_number == null ? "" : this.detall.invoice_number
                        } วันที่ : ${this.detall.invoice_date_th}`,
                        headerCellOptions: { textAlign: "" },
                        children: [
                          {
                            title: `ที่อยู่ : ${this.detall.vender_address} `,
                            headerCellOptions: { textAlign: "" },
                            children: [
                              {
                                title: `ประเภทเงิน: ${this.detall.budget_type} วิธีการได้มา : ${this.detall.supply_by}`,
                                headerCellOptions: { textAlign: "" },
                                children: [
                                  {
                                    title: "ปีที่",
                                    field: "rownum",
                                  },
                                  {
                                    title: "วัน-เดือน-ปี",
                                    field: "admitdate_th",
                                  },
                                  {
                                    title: "ที่เอกสาร",
                                    field: "equipment_code",
                                  },
                                  {
                                    title: "รายการ",
                                    field: "information",
                                  },
                                  {
                                    title: "จำนวนหน่วย",
                                    field: "unittype",
                                  },
                                  {
                                    title: "ราคาต่อหน่วย",
                                    field: "unitprice",
                                  },
                                  {
                                    title: "มูลค่ารวม",
                                    field: "unitprice",
                                  },
                                  {
                                    title: "อายุการใช้งาน",
                                    field: "expire",
                                  },
                                  {
                                    title: "จำนวนวัน",
                                    field: "daycount",
                                  },
                                  {
                                    title: "อัตราค่าเสื่อม (%)",
                                    field: "อัตราค่าเสื่อมราคาต่อปี",
                                  },
                                  {
                                    title: "ค่าเสื่อมประจำปี",
                                    field: "ค่าเสื่อมราคาประจำปี",
                                  },
                                  {
                                    title: "ค่าเสื่อมราคาสะสม",
                                    field: "ค่าเสื่อมราคาสะสม",
                                  },
                                  {
                                    title: "มูลค่าสุทธิ",
                                    field: "มูลค่าสุทธิ",
                                  },
                                ],
                              },
                              {
                                title: ``,
                                headerCellOptions: { textAlign: "center" },
                                children: [],
                              },
                            ],
                          },
                          {
                            title: ``,
                            headerCellOptions: { textAlign: "center" },
                            children: [],
                          },
                        ],
                      },
                      {
                        title: ``,
                        headerCellOptions: { textAlign: "center" },
                        children: [],
                      },
                    ],
                  },
                  {
                    title: ``,
                    headerCellOptions: { textAlign: "center" },
                    children: [],
                  },
                ],
              },
              {
                title: ``,
                headerCellOptions: { textAlign: "center" },
                children: [],
              },
            ],
          },
        ],
      });
    },
    async getdata() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}headerPropertyRegistration?e_number=${this.equipment.e_number}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      // [this.detall] = await res.data.message.data;

      [this.detall] = await res.data.message.data.map((element) => {
        console.log(element);
        console.log(element.invoice_date);
        console.log(element.important_date);
        if (element.important_date || element.invoice_date) {
          return {
            ...element,
            // important_date_th: `${parseInt(element.important_date.split('-')[2])} ${this.month[parseInt(element.important_date.split('-')[1])]} ${
            //   Number(element.important_date.split('-')[0]) + 543
            // }`,
            important_date_th: `${
              element.important_date !== " "
                ? parseInt(element.important_date.split("-")[2]) +
                  " " +
                  this.month[parseInt(element.important_date.split("-")[1])] +
                  " " +
                  Number(element.important_date.split("-")[0]) +
                  543
                : "-"
            }`,
            invoice_date_th: `${
              element.invoice_date !== " "
                ? parseInt(element.invoice_date.split("-")[2]) +
                  " " +
                  this.month[parseInt(element.invoice_date.split("-")[1])] +
                  " " +
                  Number(element.invoice_date.split("-")[0]) +
                  543
                : "-"
            }`,
          };
        } else {
          return {
            ...element,
            important_date_th: "-",
            invoice_date_th: "-",
            invoice_date: "-",
            important_date: "-",
          };
        }
      });
      console.log(this.detall);
      this.show = false;
    },
    async get_table_report() {
      this.show = true;
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url_life = `${API}lifetimeLists?e_number=${this.equipment.e_number}`;
      const res_life = await axios.get(url_life, header);
      console.log(res_life.data.message.data[0].lifetime_new);
      console.log(this.$route.params.id.refkey);
      console.log(this.equipment);
      const url = `${API}propertyRegistration?e_number=${this.equipment.e_number}&lifetime=${res_life.data.message.data[0].lifetime_new}&refkey=${this.$route.params.id.refkey}`;
      console.log(url);
      // 02-011-0001/65
      const res = await axios.get(url, header);
      console.log(res.data);
      // console.log(
      //   res.data.message.sum.map((el) => {
      //     return {
      //       ...el,
      //       ค่าเสื่อมราคาประจำปี: `${el.ค่าเสื่อมราคาประจำปี}`,
      //       ค่าเสื่อมราคาสะสม: `${el.ค่าเสื่อมราคาสะสม}`,
      //       มูลค่าสุทธิ: `${el.มูลค่าสุทธิ}`,
      //     };
      //   })
      // );
      //01-036-0004/65
      console.log(res.data.message.data);
      this.rows = await res.data.message.data.map((element) => {
        return {
          ...element,
          admitdate: element.admitdate,
          admitdate_th: `${parseInt(element.admitdate.split("-")[2])} ${
            this.month[parseInt(element.admitdate.split("-")[1])]
          } ${Number(element.admitdate.split("-")[0]) + 543}`,
          //   ค่าเสื่อมราคาประจำปี_ex: Number(element.ค่าเสื่อมราคาประจำปี),
          //   ค่าเสื่อมราคาสะสม_ex: Number(element.ค่าเสื่อมราคาสะสม),
          //   มูลค่าสุทธิ_ex: Number(element.มูลค่าสุทธิ),
        };
      });
      this.rows = this.rows;
      this.getdata();
      // console.log(
      //   res.data.message.data.map((element) => {
      //     return {
      //       ...element,
      //       admitdate: element.admitdate,
      //       admitdate_th: `${parseInt(element.admitdate.split('-')[2])} ${
      //         this.month[parseInt(element.admitdate.split('-')[1])]
      //       } ${Number(element.admitdate.split('-')[0]) + 543}`,
      //     };
      //   })
      // );
      this.show = false;
    },
    formatFn: function (value) {
      return value
        ? Number(value)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        : value;
    },
    cc_report() {
      this.rows = [];
      this.date.startDate = "";
      this.date.endDate = "";
      this.equipmentGroup = null;
    },
    async equipment_table() {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}equipmentGroups?_page=1&_limit=100`;
      const res = await axios.get(url, header);

      this.equipmentGroups = res.data.message.data.map((element) => {
        return {
          ...element,
          e_gid_e_gname: `${element.e_gid} - ${element.e_gname}`,
        };
      });
    },
    async workgroups_table() {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}workgroups?_page=1&_limit=100&_sort=1`;
      const res = await axios.get(url, header);
      this.workgroups = res.data.message.data.map((element) => {
        return {
          ...element,
          work_gid_work_gname: `${element.work_gid} - ${element.work_gname}`,
        };
      });
    },

    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
